<template>
	<div class="page">
		<el-main>
			<el-container>
				<el-row>
					<el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-form :inline="true">
							<el-input v-model="keyword" placeholder="请输入关键字查询" class="input-with-select" style="width: 300px; margin-right: 10px" size="medium">
								<template #prepend>
									<el-select v-model="checkInput" multiple collapse-tags placeholder="请选择" style="width: 120px">
										<el-option v-for="(item, i) in dataTableSearch" :key="i" :label="item.label" :value="item.attr"></el-option>
									</el-select>
								</template>
							</el-input>
							<slot name="searchSlot"></slot>
							<el-form-item>
								<el-button type="primary" icon="el-icon-search" size="medium" @click="SearchPage()">查询</el-button>
								<el-button type="primary" v-has="'Add'" icon="el-icon-plus" size="medium" @click="adminAdd">新增</el-button>
								<el-button type="danger" v-has="'FakeDelete'" icon="el-icon-delete" size="medium" @click="DeleteAdmin()">删除</el-button>
								<el-dropdown>
									<el-button type="primary" size="medium" v-has="'AdminImporter,ExportExcel,ImportAdminPhoto,ExportAdminPhoto'"> <i class="el-icon-arrow-down el-icon-menu"></i>批量操作 </el-button>
									<template #dropdown>
										<el-dropdown-menu>
											<el-dropdown-item v-has="'AdminImporter'" icon="el-icon-upload2" @click="importAdminDialogVisible = true">员工信息导入</el-dropdown-item>
											<el-dropdown-item v-has="'ExportExcel'" icon="el-icon-download" @click="ExportExcel()">员工信息导出</el-dropdown-item>
											<el-dropdown-item v-has="'ImportAdminPhoto'" icon="el-icon-upload2" @click="importAdminPhotoDialogVisible = true">员工照片导入</el-dropdown-item>
											<el-dropdown-item v-has="'ExportAdminPhoto'" icon="el-icon-download" @click="ExportAdminPhoto()">员工照片导出</el-dropdown-item>
										</el-dropdown-menu>
									</template>
								</el-dropdown>
								<el-dropdown style="margin-left: 10px !important">
									<el-button type="primary" size="medium" icon="el-icon-upload" v-has="'UniSyncAdminData,YqshSyncAdminData'">数据同步 </el-button>
									<template #dropdown>
										<el-dropdown-menu>
											<el-dropdown-item icon="el-icon-upload" v-has="'UniSyncAdminData'" @click="uniSyncData()">宇泛数据同步</el-dropdown-item>
											<el-dropdown-item icon="el-icon-upload" v-has="'YqshSyncAdminData'" @click="yqshSyncData()">一起生活同步</el-dropdown-item>
										</el-dropdown-menu>
									</template>
								</el-dropdown>
							</el-form-item>
							<el-form-item>
								<el-popover placement="bottom" :width="400" trigger="click">
									<template #reference>
										<el-button size="medium"><i class="el-icon-arrow-down el-icon-menu" /> 列筛选</el-button>
									</template>
									<el-checkbox-group v-model="dataTableSearchList">
										<div v-for="(item, i) in dataTableList" :key="i">
											<el-checkbox v-if="item.visible != false" :label="item" checked style="display: block; margin: 10px" @change="filterFunHandle('filter', item.attr, i)"> {{ item.label }}</el-checkbox>
											<el-checkbox v-else :label="item" style="display: block; margin: 10px" @change="filterFunHandle('filter', item.attr, i)"> {{ item.label }}</el-checkbox>
										</div>
									</el-checkbox-group>
									<el-button size="small" type="text" @click="filterFunHandle('allchecked')">全选</el-button>
									<el-button size="small" type="text" @click="filterFunHandle('cancel')">取消全选</el-button>
								</el-popover>
							</el-form-item>
						</el-form>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-table size="small" border ref="multipleTable" :data="dataTable" style="width: 99%" max-height="700" :height="tableHeight" :fit="true" @selection-change="handleSelectionChange">
							<el-table-column type="selection" fixed="left" header-align="center" align="center"></el-table-column>
							<el-table-column label="操作" fixed="right" header-align="center" align="center" width="300">
								<template #default="scope">
									<el-button size="mini" v-has="'Update'" @click="adminEdit(scope.row.Id)" type="primary" plain>修改</el-button>
									<el-popconfirm confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info" iconColor="red" title="确定删除吗？" @confirm="adminDelete(scope.row.Id)">
										<template #reference>
											<el-button type="danger" v-has="'FakeDelete'" size="mini" plain>删除</el-button>
										</template>
									</el-popconfirm>
								</template>
							</el-table-column>
							<el-table-column v-for="(col, index) in dataTableSearchList" :key="index" sortable header-align="center" align="center" :prop="col.attr" :label="col.label">
								<template #default="scope">
									<el-image v-if="col.type == 'img'" :src="scope.row[col.attr]" :preview-src-list="[scope.row[col.attr]]" fit="fill" style="width: 40px; height: 40px"></el-image>
									<el-tag size="medium" v-else-if="col.type == 'enum'">
										{{ col.formatters(scope.row[col.attr]) }}
									</el-tag>
									<div v-else-if="col.type == 'custom'">
										<el-tag size="medium" v-for="(it, ix) in scope.row[col.attr]" :key="ix">{{ it.RoleName }}</el-tag>
									</div>
									<label v-else>{{ scope.row[col.attr] }}</label>
								</template>
							</el-table-column>
						</el-table>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<paging :currentPage="currentPage" :pagesize="pageSize" :total="total" @sizeChange="handleSizeChange" @currentChange="handleCurrentChange" :pagesizes="pageSizes" />
					</el-col>
				</el-row>
			</el-container>
		</el-main>

		<el-dialog v-model="dialogVisible" :title="dialogTitle" width="30%" :before-close="handleClose" destroy-on-close>
			<saveAdmin @operationSuccess="operationSuccess" :adminId="adminId" />
		</el-dialog>

		<el-dialog title="员工信息导入" v-model="importAdminDialogVisible" width="30%" :before-close="handleClose" destroy-on-close center>
			<importAdmins @importAdminSuccess="operationSuccess" />
		</el-dialog>

		<el-dialog title="员工照片导入" v-model="importAdminPhotoDialogVisible" width="30%" :before-close="handleClose" destroy-on-close center>
			<importAdminPhoto @operationSuccess="operationSuccess" />
		</el-dialog>

		<el-dialog title="配置选择" v-model="uniDataSyncDialogVisible" width="20%" :before-close="handleClose" destroy-on-close center>
			<center>
				<el-select v-model="checkUniAppId" placeholder="请选择同步配置" filterable>
					<el-option v-for="ite in checkUniConfigData" :key="ite.AppID" :label="ite.ConfigName" :value="ite.AppID"> </el-option>
				</el-select>
			</center>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="uniDataSyncDialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="uniDataSyncSave()">确 定</el-button>
				</span>
			</template>
		</el-dialog>

		<el-dialog title="配置选择" v-model="yqshDataSyncDialogVisible" width="20%" :before-close="handleClose" destroy-on-close center>
			<center>
				<el-select v-model="checkYqshConfigId" placeholder="请选择同步配置" filterable>
					<el-option v-for="ite in checkYqshConfigData" :key="ite.Id" :label="ite.TeamName" :value="ite.Id"> </el-option>
				</el-select>
				<div class="el-form-item-msg" style="line-height: 10px; margin-top: 10px">
					注：同步人员必须设置身份证号信息!
				</div>
			</center>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="yqshDataSyncDialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="yqshDataSyncSave()">确 定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>
<script>
import saveAdmin from "./saveAdmin";
import importAdmins from "./importAdmins";
import importAdminPhoto from "./importAdminPhoto";

export default {
	name: "admin",
	components: {
		saveAdmin,
		importAdmins,
		importAdminPhoto,
	},
	data() {
		return {
			adminId: 0,
			dialogTitle: "",
			dialogVisible: false, //创建修改员工
			importAdminDialogVisible: false, //导入员工
			importAdminPhotoDialogVisible: false,
			checkUniAppId: "",
			checkUniConfigData: [],
			uniDataSyncDialogVisible: false, //员工宇泛数据同步弹出框
			yqshDataSyncDialogVisible: false, //学生一起生活数据同步弹出框
			checkYqshConfigId: "",
			checkYqshConfigData: [],
			total: 0, //数据总条数
			pageSize: 10, //当前显示数据数量
			pageSizes: [10, 20, 50, 100],
			currentPage: 1, //分页当前页
			keyword: "", //搜索输入关键字
			checkInput: "", //选择所需要查询的内容
			dataConfig: [
				{ label: "Id", attr: "Id", isInput: false, isDisplay: false },
				{ label: "头像", attr: "AdminAvatarUrl", type: "img" },
				{ label: "姓名", attr: "AdminName", isSearch: true },
				{ label: "角色", attr: "AdminRoles", type: "custom" },
				{
					label: "性别",
					attr: "Sex",
					type: "enum",
					formatters: function(val) {
						var result;
						switch (val) {
							case 1:
								result = "男";
								break;
							case 2:
								result = "女";
								break;
							default:
								result = "未知";
								break;
						}
						return result;
					},
				},
				{
					label: "账户类型",
					attr: "AdminType",
					type: "enum",
					formatters: function(val) {
						var result;
						switch (val) {
							case 1:
								result = "超级管理员";
								break;
							case 2:
								result = "管理员";
								break;
							default:
								result = "普通账户";
								break;
						}
						return result;
					},
				},
				{ label: "电话", attr: "AdminPhoneAccount", isSearch: true },
				{ label: "身份证号", attr: "AdminIDCard", isSearch: true },
				{ label: "生日", attr: "AdminBirthday", visible: false },
				{ label: "备注", attr: "RemarkInfo", visible: false },
				{ label: "创建时间", attr: "CreatedTime", visible: false },
				{ label: "更新时间", attr: "UpdatedTime", visible: false },
			],
			dataTableSearch: [], //搜索信息
			dataTableList: [], //显示列信息
			dataTableSearchList: [], //列筛选信息
			dataTable: [], //显示数据
			multipleSelection: [], //选中数据
			tableHeight: 200, //table高度
		};
	},
	mounted() {
		const that = this;
		that.$nextTick(function() {
			// 仅在整个视图都被渲染之后才会运行的代码
			//设置table位置
			let heightStyle = window.innerHeight - 230;
			that.tableHeight = heightStyle;

			//窗口大小改变时，table 高度设置
			window.onresize = () => {
				return (() => {
					let heightStyle = window.innerHeight - 230;
					that.tableHeight = heightStyle;
				})();
			};
		});
		that.SearchPage();
		that.dataTableSearch = that.dataConfig.filter((it) => it.isSearch == true); //可搜索字段
		that.dataTableList = that.dataConfig.filter((it) => it.isDisplay != false); //可显示列
	},
	methods: {
		//页面搜索查询
		SearchPage() {
			let that = this;
			let json = {
				searchValue: "",
				pageNo: that.currentPage,
				pageSize: that.pageSize,
				searchBeginTime: that.searchStartTime,
				searchEndTime: that.searchEndTime,
				sortField: "",
				sortOrder: "",
				descStr: "",
			};
			if (that.checkInput.length > 0 && that.keyword != "") {
				var searchParameters = [],
					input = that.keyword;
				for (let index = 0; index < that.checkInput.length; index++) {
					const element = that.checkInput[index];
					var searchParametersItem = {
						field: element,
						op: 8,
						value: input,
						orGroup: "",
					};
					searchParameters.push(searchParametersItem);
				}
				json.searchParameters = searchParameters;
			}

			that.$API.Admin.QueryByPage.post(json).then((res) => {
				if (res) {
					that.total = res.TotalRows;
					that.pagesize = res.PageSize;
					that.currentPage = res.PageNo;
					that.dataTable = res.Rows;
				}
			});
		},
		handleSizeChange(val) {
			this.pageSize = val;
			this.SearchPage();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			this.SearchPage();
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		// 列筛选
		filterFunHandle(type, currentItem, index) {
			if (type === "allchecked") {
				// 全选
				this.dataTableSearchList = this.dataConfig.filter((it) => it.isDisplay != false);
			} else if (type === "cancel") {
				// 取消全选
				this.dataTableSearchList = []; // 复选框置为空，全部不选择
			}
		},
		adminAdd() {
			this.adminId = 0;
			this.dialogTitle = "新增员工";
			this.dialogVisible = true;
		},
		adminEdit(id) {
			this.adminId = id;
			this.dialogTitle = "修改员工";
			this.dialogVisible = true;
		},
		DeleteAdmin() {
			var that = this;
			if (!that.multipleSelection || that.multipleSelection.length <= 0) {
				that.$message.warning("请选择需要删除的员工！");
				return;
			}
			var ids = new Array();
			var msgStr = "";
			that.multipleSelection.forEach((it) => {
				ids.push(it.Id);
				msgStr += `【${it.AdminName}】`;
			});
			that
				.$confirm(`确认删除${msgStr}等${that.multipleSelection.length}条员工信息吗？`)
				.then(() => {
					that.$API.Admin.FakeDelete.delete(ids).then((res) => {
						if (res.Success) {
							that.$message.success(res.Message);
							that.SearchPage();
						}
					});
				})
				.catch(() => {});
		},
		adminDelete(id) {
			var that = this;
			that.$API.Admin.FakeDelete.delete([id]).then((res) => {
				if (res.Success) {
					that.$message.success(res.Message);
					that.SearchPage();
				}
			});
		},
		ExportExcel() {
			var that = this;
			var ids = new Array();
			that.multipleSelection.forEach((it) => {
				ids.push(it.Id);
			});
			let json = {
				searchValue: "",
				pageNo: that.currentPage,
				pageSize: that.pageSize,
				searchBeginTime: that.searchStartTime,
				searchEndTime: that.searchEndTime,
				sortField: "",
				sortOrder: "",
				descStr: "",
				Ids: ids,
			};
			if (that.checkInput.length > 0 && that.keyword != "") {
				var searchParameters = [],
					input = that.keyword;
				for (let index = 0; index < that.checkInput.length; index++) {
					const element = that.checkInput[index];
					var searchParametersItem = {
						field: element,
						op: 8,
						value: input,
						orGroup: "",
					};
					searchParameters.push(searchParametersItem);
				}
				json.searchParameters = searchParameters;
			}
			that.$API.Admin.ExportExcel.post(json);
		},
		ExportAdminPhoto() {
			var that = this;
			var ids = new Array();
			that.multipleSelection.forEach((it) => {
				ids.push(it.Id);
			});
			let json = {
				searchValue: "",
				pageNo: that.currentPage,
				pageSize: that.pageSize,
				searchBeginTime: that.searchStartTime,
				searchEndTime: that.searchEndTime,
				sortField: "",
				sortOrder: "",
				descStr: "",
			};
			const loading = that.$loading({
				lock: true,
				text: "数据处理中",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)",
			});
			that.$API.Admin.ExportAdminPhoto.post(json).then((res) => {
				loading.close();
				console.log(res);
				if (res.Url && res.Url != "") {
					window.location.href = res.Url;
				} else if (res.Success) {
					that.$message.success(res.Message);
				} else {
					that.$message.error(res.Message);
				}
			});
		},
		uniSyncData() {
			var that = this;
			that.checkUniAppId = "";
			that.checkUniConfigData = [];
			that.$API.UfaceConfig.GetUfaceConfigList.get().then((res) => {
				if (res && res.length > 0) {
					that.checkUniConfigData = res;
					that.uniDataSyncDialogVisible = true;
				} else {
					that.$message.error("未获取到数据同步配置!");
				}
			});
		},
		uniDataSyncSave() {
			var that = this;
			var configData = that.checkUniConfigData.filter((i) => i.AppID == that.checkUniAppId)[0];
			if (that.checkUniAppId == "" || !configData) {
				that.$message.warning("请选择同步配置!");
				return;
			}
			that.cAdminIds = [];
			var msgStr = "";
			var msgTxt = "";
			if (that.multipleSelection && that.multipleSelection.length > 0) {
				that.multipleSelection.forEach((it) => {
					that.cAdminIds.push(it.Id);
					msgStr += `【${it.AdminName}】`;
				});
				msgTxt = `确认同步员工${msgStr}等${that.multipleSelection.length}人信息`;
			} else {
				msgTxt = `确认同步全部员工信息`;
			}
			that
				.$confirm(msgTxt + `至配置【${configData.ConfigName}】吗？`, "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
					center: true,
				})
				.then(() => {
					var json = {
						AppId: that.checkUniAppId,
						AdminIds: that.cAdminIds,
					};
					that.$message.success("开始同步");
					that.uniDataSyncDialogVisible = false;
					// const loading = that.$loading({
					//   lock: true,
					//   text: "数据处理中",
					//   spinner: "el-icon-loading",
					//   background: "rgba(0, 0, 0, 0.7)",
					// });
					that.$API.Admin.UniSyncAdminData.post(json).then((res) => {
						// loading.close();
						that.uniDataSyncDialogVisible = false;
						if (res.Success) {
							that.$message.success(res.Message);
						} else {
							that.$message.error(res.Message);
						}
					});
				})
				.catch(() => {});
		},
		yqshSyncData() {
			var that = this;
			that.checkYqshConfigId = "";
			that.checkYqshConfigData = [];
			that.$API.YqshConfig.GetYqshConfigList.get().then((res) => {
				if (res && res.length > 0) {
					that.checkYqshConfigData = res;
					that.yqshDataSyncDialogVisible = true;
				} else {
					that.$message.error("未获取到数据同步配置!");
				}
			});
		},
		yqshDataSyncSave() {
			var that = this;
			var configData = that.checkYqshConfigData.filter((i) => i.Id == that.checkYqshConfigId)[0];
			if (that.checkYqshConfigId == "" || !configData) {
				that.$message.warning("请选择同步配置!");
				return;
			}
			that.cAdminIds = [];
			var msgStr = "";
			var msgTxt = "";
			if (that.multipleSelection && that.multipleSelection.length > 0) {
				that.multipleSelection.forEach((it) => {
					that.cAdminIds.push(it.Id);
					msgStr += `【${it.AdminName}】`;
				});
				msgTxt = `确认同步员工${msgStr}等${that.multipleSelection.length}人信息`;
			} else {
				msgTxt = `确认同步全部员工信息`;
			}
			that
				.$confirm(msgTxt + `至一起生活配置【${configData.TeamName}】吗？`, "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
					center: true,
				})
				.then(() => {
					var json = {
						ConfigId: that.checkYqshConfigId,
						AdminIds: that.cAdminIds,
					};
					that.$message.success("开始同步");
					that.yqshDataSyncDialogVisible = false;
					// const loading = that.$loading({
					//   lock: true,
					//   text: "数据处理中",
					//   spinner: "el-icon-loading",
					//   background: "rgba(0, 0, 0, 0.7)",
					// });
					that.$API.Admin.YqshSyncAdminData.post(json).then((res) => {
						// loading.close();
						that.yqshDataSyncDialogVisible = false;
						if (res.Success) {
							that.$message.success(res.Message);
						} else {
							that.$message.error(res.Message);
						}
					});
				})
				.catch(() => {});
		},
		operationSuccess() {
			//操作成功后，关闭弹出框，刷新数据显示
			this.dialogVisible = false;
			this.importAdminDialogVisible = false;
			this.importAdminPhotoDialogVisible = false;
			this.adminId = 0;
			this.SearchPage();
		},
		handleClose(done) {
			this.$confirm("确认关闭？")
				.then(() => {
					done();
				})
				.catch(() => {});
		},
	},
};
</script>
<style scoped>
.page {
	margin: 5px;
	padding: 20px;
	min-height: 840px;
	width: 100%;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
	width: auto;
}
.drawerTitle {
	color: #409eff;
}
</style>
